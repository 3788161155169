import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="section feature-section is-medium light-section about-section section-3" id="about">
      <div className="container">
        <div className="section-title has-text-centered">
          <div className="shadow-title">Sobre</div>
          <h2 className="title is-3 is-bold">Sobre</h2>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column is-one-third photo">
            <img src="./adrianorighi.jpg" alt="Adriano Zavareze Righi" />
          </div>
          <div className="column">
            <div className="content">
              <p className="text is-size-6">
                <strong>Gaúcho, autodidata</strong> e{" "}
                <strong>engenheiro de software</strong> com vários anos de{" "}
                experiência na área. Curiosamente sou{" "}
                <strong>bacharel em Ciências Contábeis</strong> pela{" "}
                <a href="http://www.ufsm.br/" target="_blank" title="UFSM" rel="noopener noreferrer">
                  UFSM
                </a>{" "}
                e durante o curso fiz alguns trabalhos freelancer, o que me{" "}
                proporcionou um enorme aprendizado e me fez decidir o rumo que{" "}
                iria seguir. Formado também em <strong>Sistemas de Informação</strong>{" "}
                pela{" "}
                <a
                  href="https://unisinos.br/"
                  target="_blank"
                  title="Unisinos"
                  rel="noopener noreferrer"
                >
                  Unisinos
                </a> e Especialista em Engenharia de Software pela{" "}
                <a
                  href="https://www.pucminas.br/"
                  target="_blank"
                  title="PUC Minas"
                  rel="noopener noreferrer"
                >PUC Minas</a>.
              </p>
              <p className="text is-size-6">
                Com mais de 10 anos de experiência, como freelancer atuei em projetos de controle de estoques,{" "}
                controle financeiro, blogs, websites e redes sociais. Trabalhei em empresas atendendo diversos segmentos{" "}
                como telecomunicações, agronegócio, saúde, biocombustível, financeiro, órgãos públicos e marketplaces.
              </p>
              <p className="text is-size-6">Atualmente{" "}
                trabalho como Coordenador de Sistemas/Squad Leader no{" "}
                <a
                  href="https://medium.com/luizalabs/"
                  target="_blank"
                  title="Luizalabs"
                  rel="noopener noreferrer"
                >
                  Luizalabs
                </a>. Meu foco é liderança, DevOps e conheço dezenas de tecnologias, ferramentas e metodologias.{" "}
                Gosto de falar sobre DevOps, Linux, Docker, IAC, Gitlab, AWS, GCP, automação e muito mais.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
